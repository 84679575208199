.skeleton-wrapper {
  margin: 1.2rem auto;
  padding: 0.8rem 1rem;
  border-radius: 5px;
  background: #e2e8ec;
  position: relative;
  overflow: hidden;

  &:not(:first-child) {
    .skeleton.image {
      display: none;
    }
  }
}

.skeleton {
  background: #e2e8ec;
  margin: 0.8rem 0;
  border-radius: 5px;
  overflow: hidden;
}

.skeleton.image {
  width: 100%;
  height: 200px;
  background-color: inherit;
}

.skeleton.avatar {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.skeleton.text {
  width: 30%;
  height: 1rem;
}

.skeleton.title {
  width: 60%;
  height: 1.2rem;
  margin-left: 2rem;
}

.skeleton.smalltext {
  width: 40%;
  height: 0.8rem;
  margin-left: 2rem;
  margin-bottom: 3rem;
}

.shimmer-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  animation: loading 1.5s infinite;
}

.shimmer {
  width: 70%;
  height: 100%;
  background-color: rgba(#fff, 0.3);
  box-shadow: 0 0 2rem 2rem rgba(#fff, 0.2);
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(-70%);
  }
  100% {
    transform: translateX(100%);
  }
}
