:root {
  --main-0: #f9fafa;
  --main-1: #eef0f1;
  --main-2: #d2d6db;
  --main-3: #b5bdc4;
  --main-4: #99a3ad;
  --main-5: #7d8a97;
  --main-6: #64707d;
  --main-7: #4d5760;
  --main-8: #363d44;
  --main-9: #202428;

  --main-a: rgba(8, 9, 10, 0.05);

  --main-theme: #3b49df;
  --main-theme-darker: #1827ce;
  --main-theme-lighter: #8d95f2;

  --theme-background: #eef0f1;
  --font-color: #08090a;

  --site-width: 1280px;

  --header-height: 56px;
  --header-bg: #fff;
}
