*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-weight: 300;
}

body {
  background-color: #e2e8ec;
  color: var(--font-color);
  font-family: sans-serif, "Segoe UI", Tahoma, Geneva, Verdana;
  padding: 0;
  margin: 0;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;

  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  border-style: none;
  outline: none;
  background: none;
  cursor: pointer;
}

ul {
  list-style: none;
}

.overlay {
  background-color: black;
  opacity: 0.5;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 300;
}

.main-container {
  height: 100vh;
  width: 100%;
  max-width: 100vw;
  margin: 0 auto;
  padding: 1rem 3rem;

  display: grid;
  grid-template-columns: 240px 2fr 1fr;
  grid-template-rows: 1fr;
  gap: 1rem 1rem;
}
