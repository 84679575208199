.rightBar {
  display: flex;
  flex-direction: column;

  &__card-hackathon {
    background-color: #e2e8ec;
    padding: 1rem;
    border-radius: 5px;
    line-height: 1.5;
    height: max-content;

    img {
      width: 100%;
      height: auto;
      max-height: 100px;
      border-radius: 5px;
      display: inline-block;
      margin-bottom: 1rem;
    }

    p a {
      color: var(--main-theme);
    }
  }
}
